import React from "react";

class Header extends React.Component {
  render() {
    return (
      <div id="header">
					<div id="navigation">
						<a id="logo" href=""></a>
						  <div class="region region-search">
    <div id="block-search-form" class="block block-search">

    
  <div class="content">
    <form action="/" method="post" id="search-block-form" accept-charset="UTF-8"><div>
      </div>
</form> 
 </div>
</div>
  </div>
						<div class="meta-text">
            IMODIUM®.&nbsp;Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
            </div>
						<div class="meta">
							<a href="/faq-page/">
								FAQ
							</a>
							<a href="/glossar/">
								Glossar
							</a>
							<a href="/expertenrat/">
								Expertenrat
							</a>
							<a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p9#etd=::00X,v9u?z00Y00aj00L00c?Z9W00Y00HO,WRvO00L00&amp;Z9TvZTV00Y00WV00L00vZn9vOcV?00Y00iannpB53poKKnnBB300ww$">
								Kontakt
							</a>
						</div>
						<div class="main">
															<ul id="main-menu" class="links inline clearfix"><li class="menu-597 first"><a href="/wie-wirkt-imodium-akut/">Wie wirkt IMODIUM<sup>®</sup>?</a></li>
<li class="menu-690"><a href="/imodium-produkte/" title="">Produkte</a></li>
<li class="menu-727"><a href="/fakten-uber-durchfall/">Durchfall - Ursachen &amp; Behandlung</a></li>
<li class="menu-331"><a href="/diagnose-reizdarm/">Diagnose Reizdarm</a></li>
<li class="menu-627"><a href="/reisedurchfall-vorbeugen/">Durchfall auf Reisen</a></li>
<li class="menu-337"><a href="/durchfall-bei-kindern/">Durchfall bei Kindern</a></li>
<li class="menu-615 last"><a href="/infomaterial/" title="">Infomaterial</a>
<span class="info_icon"></span></li>
</ul>													</div>
					</div>
					
					<div class="slideshow_content">
  
  <div class="slideitem slide05">
	  <h2 style={{"font-size": "30px", "line-height": "40px", "font-weight": "700"}}>
	  REISEDURCHFALL? – IMODIUM<sup>®</sup> SEIT ÜBER 40 JAHREN BEWÄHRT.
	  </h2>
	  <p>
		  <em>
		  IMODIUM<sup>®</sup> Kapseln verlangsamen die gesteigerte Darmbewegung und helfen so 
		  Wasser und wichtige Nährstoffe im Körper zu behalten.
		  </em>
	  </p>
	  <a class="btn" href="imodium-kapseln">
		  <span class="start">
			  IMODIUM<sup>®</sup> akut Kapseln
		  </span>
		  <span class="end"></span>
	  </a>
  </div>
  
			{/*<div class="slideitem slide01">
				<h2 style={{"width": "680px"}}>
					Soforthilfe bei akutem Durchfall
				</h2>
				<p>
					<em>
					  IMODIUM<sup>®</sup> akut Schmelztabletten schmelzen sofort auf<br/>
					  der Zunge. Da sie ohne Wasser einzunehmen sind, sind sie<br/>
					  ideal für unterwegs.
					</em>
				</p>
				<a class="btn" href="imodium-akut-schmelztabletten/">
					<span class="start">
						Mehr erfahren
					</span>
					<span class="end"></span>
				</a>
	</div>*/}
  
			<div class="slideitem slide02">
				<h2>
					Wie wirkt Imodium<sup>®</sup>
				</h2>
				<p>
					<em>
					    Erfahren Sie, wie akuter Durchfall Ihrem Körper wichtige Flüssigkeit und Elektrolyte entzieht und wie IMODIUM<sup>®</sup> Ihnen helfen kann.
					</em>
				</p>
				<a class="btn" href="wie-wirkt-imodium-akut/">
					<span class="start">
						Mehr erfahren
					</span>
					<span class="end"></span>
				</a>
			</div>
  
			<div class="slideitem slide04">
				<h2>
					Stress und Durchfall
				</h2>
				<p>
					<em>
					  Viele wissen nicht, dass Stress und Nervosität Auslöser von akutem Durchfall sein können. Erfahren Sie hier mehr über die Ursachen von Stress und stressbedingtem Durchfall und was Sie tun können, um diese zu vermeiden.
					</em>
				</p>
				<a class="btn" href="stress-durchfall/">
					<span class="start">
						Mehr erfahren
					</span>
					<span class="end"></span>
				</a>
			</div>
    
		</div>
		<div id="slideshow_navigation">
			<a id="slide05" href="javascript:void(0)" class="active">Durchfall auf Reisen?<sup></sup></a>{/*<a id="slide01" href="javascript:void(0)" class="">Die Soforthilfe</a>*/}<a id="slide02" href="javascript:void(0)" class="">Wie wirkt Imodium<sup>®</sup></a><a id="slide04" href="javascript:void(0)" class="">Stress und Durchfall<sup></sup></a>
			<div class="bar"></div>
		</div>
					</div>
          );
  }
}

export default Header;
