import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="skip-link">
        </div>
        
        <div className="bg" id="bg05" />
        {/*<div className="bg" id="bg01" />*/}
        <div className="bg" id="bg02" />
        <div className="bg" id="bg04" />
        <div id="wrapper">
          <div id="head">
          <Header></Header>
          </div>
          <div id="content">
            <div id="start_teaser">
              <div className="region region-teaser">
                <div id="block-block-21" className="block block-block">
                  <div className="content">
                    <div className="item">
                      <div className="left">
                        <h2>Durchfall Fakten</h2>
                        <p>
                          <em>
                            Erfahren Sie mehr über die Ursachen &amp; Behandlung
                            von Durchfall
                          </em>
                        </p>
                      </div>
                      <div className="right">
                        <a href="/fakten-uber-durchfall/">
                          <img
                            alt
                            src="/sites/default/files/teaser_bauch_startseite.png"
                            style={{
                              width: "231px",
                              height: "146px"
                            }}
                          />
                        </a>
                      </div>
                      <div className="clear">&nbsp;</div>
                    </div>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                  </div>
                </div>
                <div id="block-block-7" className="block block-block">
                  <div className="content">
                    <div className="item">
                      <div className="left">
                        <h2>FAQ´s</h2>
                      </div>
                      <div className="right">
                        <em>
                          Hier finden Sie die häufigsten Fragen und Antworten zu
                          Durchfall und IMODIUM®.
                        </em>
                      </div>
                      <div className="right">
                        <a className="arrow_01" href="/faq-page/">
                          Mehr erfahren
                        </a>
                      </div>
                      <div className="clear">&nbsp;</div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </div>
			<div id="start_teaser">
				<div class="region region-teaser" style={{"padding-left": "10px"}}>
					<iframe width="480" height="270" src="https://www.youtube.com/embed/cQ31jjQaQKc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
				</div>
			</div>
            <div className="clear" />
          </div>
        </div>
        
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    IMODIUM® ist ein Mittel zur symptomatischen Behandlung von
                    akutem Durchfall. Wirkstoff: Loperamid. Über Wirkung und
                    mögliche unerwünschte Wirkungen informieren
                    Gebrauchsinformation, Arzt oder Apotheker.
                  </p>
                  <p>
                    <br />© Johnson &amp; Johnson GmbH 2011-2019. Diese Website
                    wird von der Johnson &amp; Johnson GmbH veröffentlicht, die
                    allein für deren Inhalte verantwortlich ist. Sie ist für
                    Besucher aus Österreich bestimmt.
                    <br />
                    Letzte Aktualisierung am: 23. Dezember 2021, AT-IM-2100045
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <div id="videolayer">
          <div className="close modalClose" />
          <div className="videoplayer">
            <div id="flash_videoplayer">
              <h1>
                IMODIUM@ SPOT: Hier können Sie unseren aktuellen Apothekenfilm
                ansehen
              </h1>
              <br />
              <br />
              Please install the newest Flash Player
              <br />
              <br />
              <a href="https://www.adobe.com/go/getflashplayer">
                <img
                  src="https://www.adobe.com/images/shared/download_buttons/get_flash_player.gif"
                  alt="Get Adobe Flash player"
                  border="0"
                />
              </a>
            </div>
          </div>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html:
                "\nfunction closeVideo(){\nalert(\"closeVideo() called!\");\n}\nvar flashvars = {\nvideoUrlHigh: \"/sites/default/files/imodium_spot.mp4\",\nvideoPlayerWidth: \"428\",\nvideoPlayerHeight: \"236\"\n};\nvar params = {\nallowFullScreen: \"true\",\nallowScriptAccess: \"always\",\nquality: \"high\",\nwmode: \"transparent\"\n};\nswfobject.embedSWF(\"resources/swf/videoplayer.swf\", \"flash_videoplayer\", \"428\", \"236\", \"10\", \"../resources/swf/expressInstall.swf\", flashvars, params);\n//FIFA Layer\n//true: without animation\n//false: including height animation\nvar JnJwinCaredirect = false;\n//on dom loaded\njQuery(document).ready(function(){\njQuery('#JnJtrueWinnersClose').click(function(){\n//With or without animation\nif(JnJwinCaredirect==true)\n{\njQuery('#JnJtrueWinnersWrap').css('display','none');\n}\nelse if(JnJwinCaredirect==false)\n{\njQuery('#JnJtrueWinnersWrap').animate({'height':'0'}, function(){\njQuery('#JnJtrueWinnersWrap').css('display','none');\n});\n}\n});\nif(jQuery('#JnJtrueWinners').hasClass('langAT'))\n{\njQuery('#JnJtrueWinners .bandIMGm').attr('src','/sites/all/themes/imodium_home/FIFA-Banderole/images/otc/at/band-mob.jpg');\njQuery('#JnJtrueWinners .bandIMGt').attr('src','/sites/all/themes/imodium_home/FIFA-Banderole/images/otc/at/band-tab.jpg');\n}\n});\n//FIFA Layer\n"
            }}
          />
        </div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n<!--//--><!--[CDATA[//--><!--\nfunction euCookieComplianceLoadScripts() {}\n//--><!--]]-->\n"
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--//--><!--[CDATA[//--><!--\nvar eu_cookie_compliance_cookie_name = "";\n//--><!--]]-->\n'
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/modules/contrib/eu_cookie_compliance/js/eu_cookie_compliance.js?qbpk52"
        />
      </div>
    );
  }
}

export default Page;
